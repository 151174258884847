/* importing open sans font */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');

:root {
  --primary-color: #f7931e;
  --primary-color-selected: #f8e7d3;
  --primary-color-blur: #f7d6b0;
  --primary-color-fait: #fdf1e3;
  --success-color: #00a755;
  --error-color: #f30b0b;
  --purple-color: #7b61ff;
  --info-color: #05afba;
  --secondary-color: #464f60;
  --blur-color: #a4a4a4;
  --white-color: #fff;
  --gray-color: #f5f5f5;
  --dark-color: #0c0a0a;
  --dark-blur-color: #333;
  --border-color: #ccc;
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
}

/* ============== Course Schedule Taps Style ================= */
.MuiTab-textColorPrimary,
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  color: var(--dark-color) !important;
  background-color: var(--gray-color) !important;
}

.Mui-selected {
  background-color: var(--primary-color) !important;
  color: var(--white-color) !important;
}

.MuiTabs-indicator,
.css-1aquho2-MuiTabs-indicator {
  background-color: transparent !important;
}

.MuiTabs-flexContainer,
.css-heg063-MuiTabs-flexContainer {
  background-color: var(--white-color) !important;
}

/* ===================== Course Schedule Table Header =============== */
.MuiBox-root,
.css-19kzrtu {
  padding: 0 !important;
  margin: 0 !important;
}

.MuiTableHead-root,
.css-15wwp11-MuiTableHead-root {
  height: 20px !important;
  padding: 0 !important;
}

.MuiTableCell-root,
.MuiTableCell-head,
.MuiTableCell-sizeMedium,
.css-14qcyd1-MuiTableCell-root {
  height: 20px !important;
  padding: 12px 8px !important;
}

.MuiTableRow-root .css-ntblk4-MuiTableRow-root {
  padding-bottom: 2rem !important;
}

/* ======= selected table border ========= */
.selected__table-row {
  border-left: 1px solid var(--primary-color) !important;
  background-color: var(--primary-color-selected) !important;
  padding-left: 4px !important;
}

/* ================ Success Page =================== */
.success__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
/* ================ Endn Success Page =================== */

/* course registration frist page  */

.content__info-form > div {
  margin-bottom: 1.6rem !important;
}

.course__schedule-remarks {
  margin-top: 4rem !important;
  font-size: 14px;
  color: #333333;
  /* color: var(--secondary-color); */
}

.course__schedule-remarks > div > p {
  line-height: 1.6rem !important;
}

.course__checkbox {
  background: #ffffff !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgba(70, 79, 96, 0.16), 0px 2px 5px rgba(89, 96, 120, 0.1) !important;
  border-radius: 4px !important;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 1%;
  top: 1%;
}

.agreement__checkbox {
  box-sizing: border-box !important;
  background: #ffffff !important;
  border: 1px solid #c3c7c9 !important;
  border-radius: 4px !important;
  position: absolute;
  width: 1.8rem !important;
  height: 1.8rem !important;
  left: 2%;
  top: 1%;
}

/* phone number input style */
.PhoneInputInput {
  letter-spacing: inherit;

  padding: 4px 0 5px;
  border: 1px solid rgb(212, 208, 208);
  border-radius: 6px;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding: 17px 14px;
}

.PhoneInputInput:hover {
  border: 0.2px solid var(--primary-color) !important;
  outline: 0.2px solid var(--primary-color) !important;
}

.PhoneInputInput:focus {
  border: 1px solid var(--primary-color) !important;
  outline: 1px solid var(--primary-color) !important;
}

.PhoneInputCountry {
  /* visibility: hidden !important; */
  display: none !important;
}

.PhoneInputCountrySelect > option {
  background-color: var(--white-color) !important;
  color: var(--primary-color) !important;
}

.PhoneInputCountrySelect > option:hover {
  background-color: var(--primary-color) !important;
  color: var(--white-color) !important;
}

.course-headers {
  color: #0c0a0a !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 24px !important;
}

/* selected Tap */
.Mui-selected {
  font-weight: 700 !important;
}

button.MuiTab-root,
.MuiTab-textColorPrimary {
  min-width: 155px;
  height: 40px;
}

.table__header-first {
  margin-left: 2.1rem !important;
}

/* date time css */
.css-kjd0cg-MuiButtonBase-root-MuiIconButton-root-MuiClock-amButton,
.css-1wahiw3-MuiButtonBase-root-MuiIconButton-root-MuiClock-pmButton {
  background-color: var(--primary-color) !important;
  color: #fff !important;
}

.css-1d3sibr-MuiButtonBase-root-MuiIconButton-root-MuiClock-amButton,
.css-ih1j2u-MuiButtonBase-root-MuiIconButton-root-MuiClock-pmButton {
  background-color: var(--primary-color) !important;
  color: #fff !important;
}

.checkbox {
  box-sizing: border-box !important;
  background: #ffffff !important;
  border: 1px solid #969da1 !important;
  border-radius: 1px !important;
  position: absolute;
  width: 1.5rem !important;
  height: 1.5rem !important;
  left: 2%;
  top: 1%;
  margin: 0.4rem 1rem !important;
}

.easy-edit-component-wrapper > textarea {
  width: 300px;
  height: 50px;
  border: 1px solid silver;
  outline: none;
  border-radius: 6px;
  font-size: 14px;
  padding: 10px;
}

.easy-edit-component-wrapper > input {
  width: 100px;
  height: 30px;
  border: 1px solid silver;
  outline: none;
  border-radius: 6px;
  font-size: 14px;
  padding: 5px;
}

.easy-edit-button-wrapper > button {
  cursor: pointer;
  margin-top: 6px;
  padding: 6px;
  border-radius: 4px;
  background-color: var(--primary-color);
  color: var(--white-color);
}

.PhoneInputInput {
  padding:17px 8px;
}

.MuiToggleButton-standard  {
  text-transform: none !important;
  outline: none;
}

.MuiButton-contained, .MuiButton-outlined {
  text-transform: none !important;
}

.MuiFormControlLabel-asterisk, .css-sp68t1 {
  display: none;
}